import $ from "jquery";
import slick from "slick-slider";

$(document).ready(function() {

    if($('.home-intro-slider').length) {
        $('.home-intro-slider').slick({
            arrows: false,
        })
    }

    if($('.home-collection-slider').length) {
        $('.home-collection-slider').slick({
            arrows: false,
        })
    }

    if($('.collection-previews__slider').length) {
        $('.collection-previews__slider').slick({
            arrows: false,
        })
    }

    if($('.drop-down-btn').length) {
        $('.drop-down-btn').on('click', function() {
            const btn = $(this)
            const dropBlock = btn.parents('.section').find('.drop-down-block')

            dropBlock.slideToggle()
            btn.toggleClass('active')
        })
    }

    if($('.filter-list').length) {
        $('.filter-list').on('click', function(e) {
            if($(e.target).is('.drop-btn') || $(e.target).parents().is('.drop-btn')) {
                const btn = $(e.target).is('.drop-btn') ? $(e.target) : $(e.target).parents('.drop-btn')

                btn.parent().children('.drop-el').slideToggle()
                btn.toggleClass('active')
                btn.parent().toggleClass('active')
                btn.parent().children('.drop-el').toggleClass('active')

            }
        })
    }

    if($('.product-view__navigation-slider').length && $('.product-view__slider').length) {
        $('.product-view__navigation-slider').slick({
            slidesToShow: 4,
            asNavFor: '.product-view__slider',
            arrows: true,
            prevArrow: '<button class="product-view__navigation-btn product-view__navigation-btn--prev nulled-btn"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid" width="20" height="11" viewBox="0 0 20 11">\n' +
                '  <defs>\n' +
                '    <style>\n' +
                '      .cls-1 {\n' +
                '        fill: #333;\n' +
                '        fill-rule: evenodd;\n' +
                '      }\n' +
                '    </style>\n' +
                '  </defs>\n' +
                '  <path d="M19.136,10.168 C18.870,10.434 18.440,10.434 18.174,10.168 L10.000,1.994 L1.826,10.168 C1.560,10.434 1.130,10.434 0.864,10.168 C0.599,9.903 0.599,9.472 0.864,9.207 L9.519,0.552 C9.785,0.286 10.215,0.286 10.481,0.552 L19.136,9.207 C19.401,9.472 19.401,9.903 19.136,10.168 Z" class="cls-1"/>\n' +
                '</svg></button>\n',
            nextArrow: '<button class="product-view__navigation-btn product-view__navigation-btn--next nulled-btn"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid" width="20" height="11" viewBox="0 0 20 11">\n' +
                '  <defs>\n' +
                '    <style>\n' +
                '      .cls-1 {\n' +
                '        fill: #333;\n' +
                '        fill-rule: evenodd;\n' +
                '      }\n' +
                '    </style>\n' +
                '  </defs>\n' +
                '  <path d="M19.136,10.168 C18.870,10.434 18.440,10.434 18.174,10.168 L10.000,1.994 L1.826,10.168 C1.560,10.434 1.130,10.434 0.864,10.168 C0.599,9.903 0.599,9.472 0.864,9.207 L9.519,0.552 C9.785,0.286 10.215,0.286 10.481,0.552 L19.136,9.207 C19.401,9.472 19.401,9.903 19.136,10.168 Z" class="cls-1"/>\n' +
                '</svg></button>\n',
            vertical: true,
            infinite: false,
        })

        $('.product-view__slider').slick({
            asNavFor: '.product-view__navigation-slider',
            arrows: false,
            infinite: false,
        })
    }

    if($('.chars').length) {
        $('.chars .drop-btn').on('click', function() {
            const btn = $(this)
            const wrapper = btn.parents('.chars-item')
            const content = wrapper.children('.drop-content')

            wrapper.toggleClass('open')
            content.slideToggle()
        })
    }
})
